













import Vue from "vue";

import ImageComponent from "@/components/images/Image.vue";
import Image from "@/assets/Image";
import UserService from "@/services/user.service";

export default Vue.extend({
  name: "TabLikes",

  components: {
    ImageComponent
  },

  data(): { images: Image[] } {
    return {
      images: []
    };
  },

  computed: {
    userId() {
      return this.$store.state.profil.user?.id;
    }
  },

  watch: {
    userId() {
      this.update();
    }
  },

  mounted() {
    this.update();
  },

  methods: {
    async update() {
      if (this.userId) {
        const response = await UserService.getUserLikes(this.userId);
        this.images = response.data;
      }
    }
  }
});
