





















import Vue from "vue";

import TabUser from "./TabUser.vue";
import TabCreations from "./TabCreations.vue";
import TabLikes from "./TabLikes.vue";
import TabHeader from "./TabHeader.vue";

export default Vue.extend({
  components: {
    TabLikes,
    TabCreations,
    TabHeader,
    TabUser
  },

  data() {
    return {
      currentTab: "Creations",
      tabs: ["User", "Likes", "Creations"]
    };
  },

  computed: {
    currentTabComponent(): string {
      return "Tab" + this.currentTab;
    },
    index(): number {
      return this.tabs.indexOf(this.currentTab);
    }
  }
});
