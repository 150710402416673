










import Vue from "vue";

export default Vue.extend({
  props: {
    tab: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classObject() {
      return {
        "text-white bg-dark hover:-translate-y-1": this.active,
        "bg-blue-200 translate-y-1 hover:translate-y-0 pb-1": !this.active
      };
    }
  }

  // methods: {
  //   tabChange() {
  //     this.$emit("tabChange", this.tab);
  //   }
  // }
});
