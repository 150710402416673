








import Vue from "vue";
import { Route } from "vue-router";

import TabContainer from "@/components/tabs/TabContainer.vue";

const Profil = Vue.extend({
  // redirection vers la page de connexion si non connecté
  beforeRouteEnter(to: Route, from: Route, next: Function) {
    next((vm: Vue) => {
      // si non déjà connecté, en cours de connexion ou sur la page d'un autre utilisateur...
      if (
        !vm.$store.state.auth.loggedIn &&
        !vm.$store.state.auth.loggingIn &&
        !vm.$route.params.username
      ) {
        // redirection
        vm.$router.replace("/connexion");
      } else if (vm.$route.params.username) {
        // fetch user
        document.title = "Pixit - Profil de " + vm.$route.params.username;
        vm.$store.dispatch("profil/peekAtUser", vm.$route.params.username);
      } else {
        document.title = "Pixit - Mon profil";
      }
    });
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    username() {
      return (
        this.$route.params.username || this.$store.state.auth.user.username
      );
    }
  },

  components: {
    TabContainer
  },

  watch: {
    username(newName: string) {
      if (newName === this.$store.state.auth.user.username) {
        this.$store.commit(
          "profil/userPeekSuccess",
          this.$store.state.auth.user
        );
      } else {
        this.$store.dispatch("profil/peekAtUser", newName);
      }
    }
  }
});
export default Profil;
