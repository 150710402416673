


























































import Vue from "vue";

// import { User } from "@/assets/User";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "TabUser",

  components: {
    Tooltip
  },

  data() {
    return { user: this.$store.state.profil.user };
  },

  computed: {
    joinDate(): string {
      return new Date(this.user.join_date).toLocaleDateString();
    },
    avatar(): string {
      // TODO Avatar
      return this.user.username.charAt(0).toUpperCase();
    }
  },

  watch: {
    "$store.state.profil.user.id"() {
      this.user = this.$store.state.profil.user;
    }
  }
});
