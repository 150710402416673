

























import Vue from "vue";
export default Vue.extend({
  name: "Tooltip",

  props: {
    top: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      visible: false
    };
  },

  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    toggleVisible() {
      this.visible = !this.visible;
    }
  }
});
